<template>
  <div class="regulations">
    <div class="list-nav">
      <div class="nav-box">
        <span
          ><span
            ><a href="#/" class="nav-title router-link-active">首页</a>
          </span></span
        ><span
          ><span class="nav-title"> <span>&gt;</span>其他服务</span></span
        >
        <span
          ><span
            ><span>&gt;</span><a class="nav-title">上市公司资讯</a></span
          ></span
        >
      </div>
    </div>
    <div class="regulations-search">
      <div class="search-kind search-text" style="margin-left: 0">
        <span class="kind-name">来源</span>
        <div class="enter-name notice-enter">
          <input
            id="enter_input"
            v-model="externalSource"
            class="enter-input"
            type="text"
            placeholder="请输入来源"
            style="width: 151px"
          />
        </div>
      </div>
      <div class="search-time marign-time">
        <span class="time-name">日期选择</span>
        <div class="select-time">
          <i class="date-time start-date-icon"></i>
          <input
            type="text"
            v-model="startTime"
            id="start_date"
            readonly="readonly"
            class="start-date"
            placeholder="开始日期"
          />
          -
          <input
            type="text"
            v-model="endTime"
            id="end_date"
            readonly="readonly"
            class="end-date"
            placeholder="结束日期"
          />
          <i class="date-time end-date-icon"></i>
        </div>
      </div>
      <div class="search-kind search-text marign-text">
        <span class="kind-name">标题</span>
        <div class="enter-name notice-enter">
          <input
            id="enter_input2"
            v-model="title"
            class="enter-input"
            type="text"
            placeholder="请输入标题"
          />
          <a
            href="javascript:;"
            id="search_btn"
            class="search-btn notice-search"
            @click="search"
            >搜索</a
          >
        </div>
      </div>
    </div>
    <div class="list-tabel">
      <div class="each-regulations list-head">
        <span class="date">时间</span>
        <span class="title">标题</span>
        <span class="office">相关公司</span>
        <span class="enclosure">舆情来源</span>
      </div>
      <template v-if="parseInt(listArr.length) > 0">
        <div id="list_data">
          <div v-for="(item, index) in listArr" :key="index">
            <router-link class="each-regulations" :to="`/list/sentiment-detail?columnId=${$route.query.columnId}&contentID=${item.contentID}`" target="_blank">
              <span class="date">{{ item.publishTime }}</span>
              <span class="title" :title="item.title">{{ item.title }}</span>
              <span class="office" :title="item.title">{{ item.tagName }}</span>
              <span class="enclosure">{{ item.externalSource }}</span>
            </router-link>
          </div>
        </div>
        <p
          class="load-more"
          v-if="!finished && !loadMore && pageNo >= 2"
          @click="getMore"
        >
          <a href="javascript:;">加载更多</a>
        </p>
        <p class="load-finish" v-if="finished && pageNo > 2">加载完毕</p>
        <p class="loading-text" v-if="loadMore">
          <img src="@/assets/img/article_list/loading.gif" />加载中...
        </p>
      </template>
      <p class="loading-text" v-else-if="loading">
        <img src="@/assets/img/article_list/loading.gif" />加载中...
      </p>
      <template v-else>
        <NoData v-if="!loadFlag" />
      </template>
    </div>
  </div>
</template>
<script>
import "./sentiment.css";
import { setCatalogTree } from "@/utils/store-data";
import { sentimentFind } from "@/api/list";
import BuryingPont from "@/mixins/buryingPoint.js";
import NoData from "@/components/NoData";
export default {
  components: { NoData },
  mixins: [BuryingPont],
  data() {
    return {
      listArr: [], // 列表数据
      subNav: ["首页", "投资公告", "上市公司资讯"], // 面包屑
      pageNo: 1,
      pageSize: 20,
      finished: false,
      loadMore: false,
      loading: false,
      loadFlag: false,
      externalSource: "",
      title: "",
      startTime: "",
      endTime: "",
    };
  },
  destroyed() {
    window.removeEventListener("scroll", this.scrollFns);
  },
  created() {
    setCatalogTree(this.$store);
    this.loading = true;
    this.getListData();
    this.loadSecond();
  },
  mounted() {
    window.addEventListener("scroll", this.scrollFns);
    var layui = window.layui;
    var that = this;

    layui.use("laydate", function () {
      var laydate = layui.laydate;
      var endDate = laydate.render({
        elem: "#end_date",
        // show: true,
        theme: "#005BAC",
        btns: ["clear", "confirm"],
        min: "1900-1-1",
        done: function (value) {
          that.endTime = value;
        },
      });
      laydate.render({
        elem: "#start_date",
        // show: true,
        theme: "#005BAC",
        btns: ["clear", "confirm"],
        max: "2099-12-31",
        done: function (value, date) {
          that.startTime = value;
          if (new Date(that.startTime).getDate() > new Date(that.endTime).getDate()) {
            that.endTime = '';
          }
          if (date) {
            endDate.config.min = {
              year: date.year || "1990",
              month: date.month - 1 >= 0 ? date.month - 1 : "1",
              date: date.date || "1",
            };
          }
        },
      });
    });
  },
  methods: {
    // 列表数据
    getListData() {
      if (!this.loadFlag) {
        this.loadFlag = true;
        var listData = {
          pageNo: this.pageNo,
          pageSize: this.pageSize,
          startTime: this.startTime,
          endTime: this.endTime ? this.endTime + ' 23:59:59':'',
          title: this.title,
          externalSource: this.externalSource,
        };
        sentimentFind(listData)
          .then((res) => {
            if (this.pageNo === 1) {
              this.listArr = res.data || [];
              if (!res.data || res.data?.length < this.pageSize) {
                this.finished = true;
              }
            } else {
              this.listArr = this.listArr.concat(res.data || []);
              if (
                this.pageNo >= 2 &&
                (!res.data || res.data?.length < this.pageSize)
              ) {
                this.finished = true;
              }
            }
            // 浏览埋点
              this.sensors.track( 'LY_view_list_page',{
                AppName: this.$store.state.AppName,
                page_id: '',
                total_page: res.count % this.pageSize <= 0 ? res.count / this.pageSize : parseInt(res.count / this.pageSize) + 1,
                first_level_column: res.data[1].title,
                second_level_column: res.data[2].title,
                number: res.count,
                is_login: false,
                platform: this.$store.state.platform
              })
          })
          .finally(() => {
            this.loading = false;
            this.loadMore = false;
            this.loadFlag = false;
          });
      }
    },
    // 2k屏没有滚动条时加载第二页
    loadSecond() {
      var that = this;
      var loadData = setInterval(function () {
        if (!this.finished && !that.loadFlag && that.pageNo < 2) {
          that.scrollFns();
        }
        if (that.pageNo >= 2 || this.finished) {
          clearInterval(loadData);
        }
      }, 100);
    },
    getMore() {
      this.pageNo++;
      this.loading = true;
      this.loadMore = true;
      this.finished = false;
      this.getListData();
    },
    // 滚动事件
    scrollFns() {
      var scrollH = document.body.scrollHeight;
      var clientH = document.body.clientHeight;
      var toTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (
        scrollH - clientH - toTop < 322 &&
        !this.loading &&
        !this.finished &&
        this.pageNo < 2
      ) {
        this.loading = true;
        this.loadMore = true;
        this.finished = false;
        this.pageNo++;
        this.getListData();
      }
    },
    search() {
      this.pageNo = 1;
      this.loading = true;
      this.loadMore = true;
      this.finished = false;
      this.listArr = []
      this.getListData();
      this.loadSecond();
    },
  },
  watch: {
    $route() {
      this.pageNo = 1;
      this.finished = false;
      this.loadMore = false;
      this.loading = false;
      this.listArr = [];
      this.getListData();
      this.loadSecond();
    },
  },
};
</script>